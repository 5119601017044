.ant-upload-list-picture-card{
  width: 300px;
  height: 200px;
}
.ant-upload-select, .ant-upload-list-item-container{
  width: 100%!important;
  height: 100%!important;
}

.custom-ant-btn:hover {
  background-color: #247F4D !important;
  border-color: #247F4D !important;
}

.ant-btn-variant-solid{
  background-color: #247F4D !important;

}

.ant-tabs .ant-tabs-tab:hover
{
  color: #247F4D !important;  
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn 
{
  color: #247F4D !important;
}