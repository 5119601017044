/* Custom styles for smaller upload list items */
.ant-upload-list.ant-upload-list-picture .ant-upload-list-item-container {
    padding: 4px 8px; /* Reduce padding for smaller appearance */
    font-size: 12px; /* Smaller font size for text */
  }
  
  .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-thumbnail {
    width: 40px;
    height: 40px; /* Smaller thumbnail size */
  }
  
  .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-name {
    font-size: 12px; /* Smaller font size for text */
  }
  
  .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-info > span {
    line-height: 40px; /* Align with smaller thumbnail */
  }
  
  .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-actions {
    font-size: 12px; /* Smaller action button size */
  }
  